<template>
  <b-modal
      id="confirm-patient-deletion"
      centered
      hide-header
      hide-footer
    >
      <div class="header">
        <div class="header-content">
          <v-error-x />
          <p>Excluir paciente</p>
        </div>
      </div>
      <div class="body">
        Você deseja realmente excluir este paciente, isso inclui <strong>excluir o
        prontuário, todo o histórico e documentos registrados na clínica.</strong>
        <br>
        Deseja continuar?
      </div>

      <div class="actions">
        <b-button
          class="cancel-button"
          variant="outline-danger"
          @click="confirmedDelete(false)"
        >
          Cancelar
        </b-button>
        <b-button
          variant="primary"
          @click="confirmedDelete(true)"
        >
          Confirmar
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import ErrorX from '@/assets/icons/error-x.svg'

export default {
  name: 'ConfirmPatientDeletion',
  components: {
    'v-error-x': ErrorX,
  },
  props: {
    confirmedDelete: Function,
  },
}
</script>

<style lang="scss">
  #confirm-patient-deletion {
    .modal-content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 640px;
      text-align: center;
      border: none;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        padding-top: 40px;
        .header {
          .header-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              margin-top: 40px

              svg {
                width: 96px;
                aspect-ratio: 1;
              }

              p {
                margin-top: 32px;
                font-weight: 700;
                font-size: 32px;
                line-height: 126%;
                letter-spacing: -0.01em;
                color: var(--dark-blue);

              }
          }
        }
        .body {
          padding: 16px 80px 0 80px;
          color: var(--type-active);
          font-size: 16px;
          line-height: 150%;
          text-align: center;

          strong {
            font-weight: 700;
          }

        }

        .actions {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding-top: 48px;
          padding-bottom: 40px;

          .cancel-button {
            font-weight: bold !important;
            border-color: var(--states-red-soft) !important;
            margin-right: 16px
          }
        }
      }
    }
  }
</style>
